import { useEffect, useState, useCallback, useMemo } from "react";
import {
  useTelemetry,
  useDevice,
  moment,
  lodash as _,
} from "@datwyler/mfe-shared-components";
import { transformDeviceOption, transformTempHumidity } from "@helpers";
import { HUMIDITY, MODEM_TEMPERATURE, TEMPERATURE } from "@constants";

type tempHumidityChartType = Array<[number, number]>;

const useTelemetryHook = (tenantId, sites) => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>("week");
  const [selectedSite, setSelectedSite] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<string>();
  const [selectedTelemetry, setSelectedTelemetry] = useState<string>(HUMIDITY);
  const [latestTelemetry, setLatestTelemetry] = useState({humidity: {}, temperature: {}});
  const [devices, setDevices] = useState([]);
  const [chartData, setChartData] = useState<tempHumidityChartType>([]);
  const [precedingChartData, setPrecedingChartData] =
    useState<tempHumidityChartType>([]);

  const { fetchDevices, fetchDeviceData } = useDevice();
  const {
    fetchAverageTelemetries,
    fetchAverageTelemetriesData,
    fetchLatestTelemetry,
    fetchLatestTelemetryData,
  } = useTelemetry();

  const {
    fetchAverageTelemetries: fetchPrecedingAverageTelemetries,
    fetchAverageTelemetriesData: fetchPrecedingAverageTelemetriesData,
  } = useTelemetry();

  const calculateInterval = useMemo(() => {
    switch (selectedPeriod) {
      case "week":
        return "4 hour";
      case "day":
        return "1 hour";
      case "hour":
        return "1 minute";
    }
  }, [selectedPeriod]);

  const getDevices = useCallback(
    (siteId: string) => {
      fetchDevices({
        variables: {
          tenantId: tenantId,
          filter: [`siteId:${siteId}`],
          page: {
            size: 999999,
            number: 0,
          },
        },
      });
    },
    [fetchDevices, tenantId]
  );

  const getLatestTelemetry = useCallback(() => {
    if (_.every([tenantId, selectedDevice])) {
      fetchLatestTelemetry({
        variables: {
          tenantId: tenantId,
          deviceId: selectedDevice,
          filter: {
            name: [HUMIDITY, TEMPERATURE, MODEM_TEMPERATURE],
          },
        },
      });
    }
  }, [fetchLatestTelemetry, tenantId, selectedDevice]);

  const getPrecedingAverageTelemetries = useCallback(
    (startTime, endTime = "") => {
      const temperature = _.get(latestTelemetry, 'temperature.name', 'temperature');
      fetchPrecedingAverageTelemetries({
        variables: {
          tenantId: tenantId,
          deviceId: selectedDevice,
          name: selectedTelemetry === TEMPERATURE ? temperature : selectedTelemetry,
          sort: {
            time: "DESC",
          },
          interval: calculateInterval,
          startTime,
          ...(endTime && { endTime }),
        },
      });
    },
    [
      tenantId,
      selectedDevice,
      selectedTelemetry,
      latestTelemetry,
      calculateInterval,
      fetchPrecedingAverageTelemetries,
    ]
  );

  const getAverageTelemetries = useCallback(
    (startTime, endTime = "") => {
      const temperature = _.get(latestTelemetry, 'temperature.name', 'temperature');
      fetchAverageTelemetries({
        variables: {
          tenantId: tenantId,
          deviceId: selectedDevice,
          name: selectedTelemetry === TEMPERATURE ? temperature : selectedTelemetry,
          interval: calculateInterval,
          sort: {
            time: "DESC",
          },
          startTime,
          ...(endTime && { endTime }),
        },
      });
    },
    [
      tenantId,
      selectedDevice,
      latestTelemetry,
      selectedTelemetry,
      calculateInterval,
      fetchAverageTelemetries,
    ]
  );

  useEffect(() => {
    const defaultSiteSelection =
      selectedSite || _.get(sites, 0, { value: "" }).value;
    if (defaultSiteSelection) {
      getDevices(defaultSiteSelection);
      setSelectedSite(defaultSiteSelection);
    }
  }, [sites, selectedSite, getDevices]);

  useEffect(() => {
    const deviceOptions = transformDeviceOption(fetchDeviceData);
    const defaultDeviceOption = _.get(deviceOptions, 0, { value: "" }).value;
    setDevices(deviceOptions);
    setSelectedDevice(defaultDeviceOption);
  }, [fetchDeviceData]);

  useEffect(() => {
    if (_.every([selectedSite, selectedDevice, selectedPeriod, tenantId])) {
      const startTime = moment().subtract(1, selectedPeriod).unix();
      const startTimePreceding = moment
        .unix(startTime)
        .subtract(1, selectedPeriod)
        .unix();
      getAverageTelemetries(startTime);
      getPrecedingAverageTelemetries(startTimePreceding, startTime);
    }
  }, [
    getAverageTelemetries,
    getPrecedingAverageTelemetries,
    selectedSite,
    selectedDevice,
    selectedPeriod,
    tenantId,
  ]);

  useEffect(() => {
    getLatestTelemetry();
  }, [getLatestTelemetry]);

  useEffect(() => {
    const telemetryData = _.get(
      fetchAverageTelemetriesData,
      "averageTelemetries.telemetries",
      []
    );
    const telemetryDataPreceding = _.get(
      fetchPrecedingAverageTelemetriesData,
      "averageTelemetries.telemetries",
      []
    );
    const data = transformTempHumidity(telemetryData);
    const precedingData = transformTempHumidity(telemetryDataPreceding);

    setChartData(data);
    setPrecedingChartData(precedingData);
  }, [fetchAverageTelemetriesData, fetchPrecedingAverageTelemetriesData]);

  useEffect(() => {
    const latestTelemetryData = _.get(
      fetchLatestTelemetryData,
      "latestTelemetries",
      []
    );

    let humidity = {};
    let temperature = {};
    latestTelemetryData.find((data) => {
      if (data.name === HUMIDITY) {
        humidity = { ...data };
      } else if (data.name === TEMPERATURE) {
        temperature = { ...data };
      } else if (_.isEmpty(temperature) && data.name === MODEM_TEMPERATURE) {
        temperature = { ...data };
      }
    });
    setLatestTelemetry({
      humidity,
      temperature,
    });
  }, [fetchLatestTelemetryData]);
  return {
    setSelectedTelemetry,
    setSelectedPeriod,
    setSelectedDevice,
    setSelectedSite,
    selectedTelemetry,
    selectedPeriod,
    selectedDevice,
    selectedSite,
    devices,
    chartData,
    precedingChartData,
    latestTelemetry,
  };
};

export default useTelemetryHook;
