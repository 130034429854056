import { useIntl } from "react-intl";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { Card, Row, Col } from "antd";
import { GeoJSONSourceInput } from "echarts/types/src/coord/geo/geoTypes";
import data from "./world-data";
import { MapProps, Severity } from "@types";
echarts.registerMap("world", data as GeoJSONSourceInput);
const getOption = (countries, markers, token, title) => {
  return {
    title: {
      text: title,
      left: "left",
      textStyle: {
        color: token.datwylerText.textPrimary,
      },
    },
    itemStyle: {
      areaColor: "#0C8595",
      color: "#0C8595",
    },
    visualMap: {
      left: "right",
      min: 0,
      max: 5,
      range: [0, 5],
      inRange: {
        color: ["#0C8595"],
      },
      text: ["High", "Low"],
      calculable: false,
      show: false,
    },
    select: {
      disabled: true,
    },
    series: [
      {
        name: "Data Center",
        type: "map",
        mapType: "world",
        roam: false,
        zoom: 1.8,
        center: [20, 30],
        itemStyle: {
          borderWidth: 0,
          areaColor: token.datwylerSurface.surfaceSecondary,
        },
        emphasis: { disabled: true },
        markPoint: {
          data: markers,
          symbol: `pin`,
          symbolSize: 48,
        },
        data: countries,
      },
    ],
  };
};
const WorldMap = ({ locations, token }: MapProps) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: "home_map_title" });
  const countries = locations.map((location) => ({
    name: location.country.name,
    value: location.sites?.length || 0,
  }));
  const markers = locations.map((location) => ({
    name: location.country.name,
    coord: [location.longitude, location.latitude],
    itemStyle: {
      color:
        location.highestSeverity === Severity.CRITICAL
          ? token.datwylerIcon.iconError
          : location.highestSeverity === Severity.WARNING
          ? token.datwylerIcon.iconWarning
          : token.datwylerIcon.iconInfo,
    },
  }));
  return (
    <Row>
      <Col span={24}>
        <Card>
          <ReactEcharts
            option={getOption(countries, markers, token, title)}
            style={{ height: "550px", width: "100%" }}
            className="react_for_echarts"
          />
        </Card>
      </Col>
    </Row>
  );
};
export default WorldMap;
