import { useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { Flex } from "antd";
// import { lodash as _ } from "@datwyler/mfe-shared-components";
import GridWidgets from "@components/GridWidgets";
import AddWidgetModal from "@components/AddWidgetModal";
import { WIDGETS } from "@constants";
import { HeaderProps } from "@types";
import { StyledButton } from "./styled";
import AddIcon from "@images/add.svg?component";
const Header = ({
  gridWidgets = [],
  widgetList,
  setWidgetList,
  resetWidgetList,
  handleAddWidget,
  token,
}: HeaderProps) => {
  const intl = useIntl();
  const [showAddWidgetDialog, setShowAddWidgetDialog] = useState(false);

  const widgets = useMemo(
    () =>
      WIDGETS.map((widget) => ({
        value: widget,
        label: intl.formatMessage({
          id: `home_widget_${widget.toLowerCase()}`,
        }),
      })),
    [intl]
  );

  return (
    <>
      <Flex vertical gap={16}>
        <Flex justify="right">
          <Flex gap={24}>
            <StyledButton
              icon={
                <AddIcon
                  style={{
                    color: token.datwylerIcon.iconPrimary,
                    height: "24px",
                    width: "24px",
                  }}
                />
              }
              onClick={() => setShowAddWidgetDialog(true)}
            >
              {intl.formatMessage({ id: "home_widget_btn" })}
            </StyledButton>
          </Flex>
        </Flex>
        {gridWidgets.length > 0 && (
          <GridWidgets widgets={gridWidgets} token={token} />
        )}
      </Flex>
      <AddWidgetModal
        open={showAddWidgetDialog}
        widgets={widgets}
        widgetList={widgetList}
        setWidgetList={setWidgetList}
        handleAddWidget={(widgets) => {
          handleAddWidget(widgets);
          setShowAddWidgetDialog(false);
        }}
        handleCancelWidget={() => {
          setShowAddWidgetDialog(false);
          resetWidgetList();
        }}
      />
    </>
  );
};
export default Header;
