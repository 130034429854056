import { Button } from "antd";
import { CloseIconButtonProps } from "@types";
import CloseIcon from "@images/close.svg?component";

const CloseIconButton = ({
  height,
  width,
  style,
  onClick,
}: CloseIconButtonProps) => {
  return (
    <Button
      type="text"
      onClick={onClick}
      icon={<CloseIcon height={height} width={width} style={style} />}
    />
  );
};

export default CloseIconButton;
