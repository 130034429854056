import { styled } from "@datwyler/mfe-shared-components";
export const StyledWidget = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.datwylerSurface.SurfacePrimary};
  border: 1px solid ${(props) => props.theme.datwylerBorder.borderPrimary};
  border-radius: 4px;
  padding: 24px;
`;

export const Actions = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 12px;
  top: 24px;
  right: 24px;
`;
