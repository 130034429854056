export default (data, precedingData, intl, timeperiod = "week", theme) => {
  return {
    legend: {
      left: 20,
      bottom: 0,
      itemGap: 24,
      itemWidth: 32,
      itemHeight: 0,
      padding: 0,
      textStyle: {
        color: theme.datwylerText.textTertiary,
        fontWeight: 400,
      },
    },
    xAxis: [
      {
        type: "time",
        boundaryGap: false,
        axisLabel: {
          formatter: "{MMM}-{dd} {hh} {A}",
          color: theme.datwylerText.textSecondary,
        },
        axisLine: {
          lineStyle: {
            color: theme.datwylerBorder.borderPrimary,
            fontColor: theme.datwylerText.textSecondary,
          },
        },
      },
      {
        type: "time",
        boundaryGap: false,
        show: false,
        axisLabel: {
          formatter: "{MMM}-{dd} {hh} {A}",
        },
      },
    ],
    grid: {
      left: 10,
      right: 50,
      top: 24,
      bottom: 50,
    },
    yAxis: {
      type: "value",
      position: "right",
      minInterval: 20,
      min: 0,
      max: 100,
      axisLabel: {
        color: theme.datwylerText.textSecondary,
      },
    },
    series: [
      {
        data: data,
        name: intl.formatMessage({ id: `home_widget_legend_${timeperiod}` }),
        type: "line",
        showSymbol: false,
        lineStyle: {
          color: theme.datwylerText.textBrand,
          width: 2,
        },
        tooltip: {
          textStyle: {
            color: "red",
          },
        },
      },
      {
        data: precedingData,
        name: intl.formatMessage({ id: "home_widget_legend_period" }),
        type: "line",
        xAxisIndex: 1,
        showSymbol: false,
        lineStyle: {
          color: theme.datwylerText.textBrand,
          width: 2,
          type: "dashed",
        },
      },
    ],
  };
};
