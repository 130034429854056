import { styled } from "@datwyler/mfe-shared-components";
import { Button } from "antd";

export const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  height: 48px;
  padding: 12px 24px;
  min-width: 176px;
`;
