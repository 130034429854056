import { styled } from "@datwyler/mfe-shared-components";
import { Card } from "antd";

export const StyledTitle = styled.div`
  .meter-title {
    color: ${(props) => props.theme.datwylerText.textPrimary};
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  .ant-select > .ant-select-selector {
    padding-left: 0px;
  }
`;

export const StyledCard = styled(Card)`
  box-shadow: none !important;
  .ant-card-body {
    padding: 0;
  }
  .ant-card-head {
    padding: 0px;
    border-bottom: none;
  }
`;
