import { styled } from "@datwyler/mfe-shared-components";
import { Tabs } from "antd";

export const StyledTabs = styled(Tabs)`
  margin-top: -24px;
  .ant-tabs-tab {
    align-items: start;
  }
  .ant-tabs-ink-bar {
    top: 0px;
    width: 120px;
    height: 6px !important;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .ant-tabs-nav {
    margin: 0px;
    &::before {
      border-bottom: none;
    }
  }
`;

export const TabTitle = styled.div`
  min-width: 120px;
  padding-top: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  span.title {
    color: ${(props) => props.theme.datwylerText.textBrand};
    font-size: 16px;
    font-weight: 400;
  }
  span.value {
    font-size: 32px;
    font-weight: 500;
  }
  span.change {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: ${(props) => props.theme.datwylerText.textSecondary};
  }
`;
