import { useContext } from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import { Flex, ConfigProvider } from "antd";
import {
  UserProvider,
  TenantIdProvider,
  lodash as _,
  DarkModeContext,
  PageNotFound,
} from "@datwyler/mfe-shared-components";
import Dashboard from "@containers/dashboard";
import { ThemeContextType } from "@types";

const App = () => {
  const { theme } = useContext<ThemeContextType>(DarkModeContext);
  const location = useLocation();

  const tenantId = _.get(location, "state.tenantId");

  return (
    <UserProvider>
      <TenantIdProvider tenantId={tenantId}>
        <ConfigProvider theme={theme}>
          <Flex vertical gap={"middle"}>
            <Routes>
              <Route path="/home" element={<Dashboard theme={theme} />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Flex>
        </ConfigProvider>
      </TenantIdProvider>
    </UserProvider>
  );
};

export default App;
