import { Modal } from "antd";
import { styled } from "@datwyler/mfe-shared-components";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    max-width: 528px;
    padding: 0px;
    .ant-modal-header {
      padding: 24px;
      border-bottom: 1px solid #e1e1e4;
    }
    .ant-modal-body {
        padding: 12px 24px 12px 24px;
    }
    .ant-modal-footer {
      padding: 12px 24px 24px 24px;
    }
    
    .ant-modal-footer:empty {
      padding: 12px;
    }
  }
`;

export default StyledModal;
