import { useCallback, useMemo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAlarm } from "@datwyler/mfe-shared-components";
import DeviceTable from "@components/DeviceTable";
import { transformDeviceTable } from "@helpers";
import { COOLING_SYSTEM } from "@constants";
import { CoolingUnitsProps } from "@types";

const CoolingUnits = ({ sites, actions, tenantID }: CoolingUnitsProps) => {
  const intl = useIntl();
  const [selectedSite, setSelectedSite] = useState("");
  const { fetchAlarms: fetchDeviceData, fetchAlarmData: deviceData } =
    useAlarm();
  const getDeviceData = useCallback(() => {
    tenantID && fetchDeviceData({
      variables: {
        tenantId: tenantID,
        page: {
          size: 999999,
          number: 0,
        },
        filter: {
          ...(selectedSite && { site: [{ id: selectedSite }] }),
          deviceType: [COOLING_SYSTEM],
        },
      },
    });
  }, [fetchDeviceData, tenantID, selectedSite]);

  useEffect(() => {
    getDeviceData();
  }, [getDeviceData]);

  const data = useMemo(
    () => transformDeviceTable(deviceData, COOLING_SYSTEM),
    [deviceData]
  );

  return (
    <DeviceTable
      title={intl.formatMessage({id: "home_widget_table_cooling"})}
      sites={sites}
      selectedSite={selectedSite}
      setSelectedSite={setSelectedSite}
      actions={actions}
      data={data}
    />
  );
};

export default CoolingUnits;
