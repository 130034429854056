import { useContext, useState, useMemo, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  lodash as _,
  withPermission,
  TenantIdContext,
} from "@datwyler/mfe-shared-components";
import Header from "@components/Header";
import Widgets from "@components/Widgets";
import Map from "@components/Map";
import DeleteConfirmModal from "@components/DeleteConfirmModal";
import CloseIconButton from "@components/CloseIconButton";
import useGridWidgets from "@hooks/useWidgets";
import { transformGridWidgets, transformSitesOption } from "@helpers";

import { CustomThemeConfigProps, TenantIdContextType } from "@types";

const Dashboard = ({ theme }: { theme: CustomThemeConfigProps }) => {
  const intl = useIntl();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [initialWidgetList, setInitialWidgetList] = useState<string[]>([]);
  const [currentWidgetList, setCurrentWidgetList] = useState<string[]>([]);
  const [widgetToDelete, setWidgetToDelete] = useState<string>();
  const { tenantId, tenantInfo } =
    useContext<TenantIdContextType>(TenantIdContext);

  const {
    widgetsData,
    locationsData,
    updateWidgetList,
    updateTenantResponseData,
  } = useGridWidgets(tenantId, tenantInfo);
  const { token } = theme;

  useEffect(() => {
    const list = _.get(widgetsData, "dashboard.widgets", []);
    setInitialWidgetList(list);
    setCurrentWidgetList(list);
  }, [widgetsData]);

  useEffect(() => {
    if (updateTenantResponseData) {
      const list = _.get(
        updateTenantResponseData,
        "updateTenant.tenant.dashboard.widgets",
        []
      );

      setInitialWidgetList(list);
      setCurrentWidgetList(list);
    }
  }, [updateTenantResponseData]);

  const gridWidgets = useMemo(
    () => transformGridWidgets(widgetsData, intl),
    [intl, widgetsData]
  );

  const sites = useMemo(
    () => transformSitesOption(locationsData),
    [locationsData]
  );

  const handleDeleteWidget = useCallback(() => {
    const updatedWidgetList = currentWidgetList.filter(
      (item) => item !== widgetToDelete
    );
    updateWidgetList(updatedWidgetList);
  }, [currentWidgetList, updateWidgetList, widgetToDelete]);

  const handleAddWidget = () => {
    const deletedWidgets = _.difference(initialWidgetList, currentWidgetList);
    if (deletedWidgets.length > 0) {
      // show popup if removing any widgets.
      setShowConfirmDialog(true);
    } else {
      updateWidgetList(currentWidgetList);
    }
  };

  const handleDeleteOk = () => {
    handleDeleteWidget();
    setShowConfirmDialog(false);
  };

  const handleDeleteCancel = () => {
    resetWidgetList();
    setShowConfirmDialog(false);
  };

  const resetWidgetList = () => {
    setCurrentWidgetList([...initialWidgetList]);
  };

  const getActions = useCallback(
    (widget) => {
      return [
        <CloseIconButton
          key={1}
          height={20}
          width={20}
          style={{ color: token.datwylerIcon.iconTertiary }}
          onClick={() => {
            setWidgetToDelete(widget);
            setShowConfirmDialog(true);
          }}
        />,
      ];
    },
    [token]
  );

  const commonProps = (widgetName) => ({
    actions: getActions(widgetName),
    sites: sites,
    tenantID: tenantId,
  });

  return (
    <>
      <Header
        gridWidgets={gridWidgets}
        widgetList={currentWidgetList}
        setWidgetList={setCurrentWidgetList}
        resetWidgetList={resetWidgetList}
        handleAddWidget={handleAddWidget}
        token={token}
      />
      <Map locations={locationsData} token={token} />
      <Widgets commonProps={commonProps} widgetList={initialWidgetList} />
      <DeleteConfirmModal
        open={showConfirmDialog}
        intl={intl}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
      />
    </>
  );
};

export default withPermission("/home")(Dashboard);
