import { type TabsProps } from "antd";
import withWidget from "@components/withWidget";
import { WidgetTabsProps } from "@types";
import Title from "./Title";
import { StyledTabs } from "./styled";

const Tabs = ({ tabs, defaultActiveKey, handleTabChange }: WidgetTabsProps) => {
  const items: TabsProps["items"] = tabs.map(
    ({ key, title, value, Component, unit }) => {
      return {
        key: key,
        label: <Title label={title} value={value} unit={unit} />,
        children: <Component />,
      };
    }
  );
  return (
    <>
      <StyledTabs  onChange={handleTabChange} defaultActiveKey={defaultActiveKey} items={items} />
    </>
  );
};
export default withWidget(Tabs);
