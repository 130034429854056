import { useIntl } from "react-intl";
import { Row, Col } from "antd";
import Modal from "@components/Modal";
import { Button, Title, Checkbox } from "./styled";
import { AddWidgetModalProps } from "@types";

const AddWidgetModal = ({
  widgets,
  open,
  widgetList,
  setWidgetList,
  handleCancelWidget,
  handleAddWidget,
}: AddWidgetModalProps) => {
  const intl = useIntl();

  const title = <Title>{intl.formatMessage({ id: "home_widget_btn" })}</Title>;
  const footer = (
    <Button
      disabled={widgetList.length <= 0}
      onClick={() => {
        handleAddWidget(widgetList);
      }}
      type="primary"
    >
      {intl.formatMessage({ id: "home_widget_btn" })}
    </Button>
  );

  const onChange = (list: string[]) => {
    setWidgetList(list);
  };

  return (
    <>
      <Modal
        centered
        open={open}
        title={title}
        destroyOnClose={true}
        footer={footer}
        width={528}
        onCancel={handleCancelWidget}
      >
        <Checkbox.Group
          style={{ width: "100%" }}
          value={widgetList}
          onChange={onChange}
        >
          <Row gutter={[24, 24]}>
            {widgets.map(({ value, label }, index) => {
              return (
                <Col span={24} key={index}>
                  <Checkbox value={value}>
                    {intl.formatMessage({ id: label })}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Modal>
    </>
  );
};

export default AddWidgetModal;
