import { useEffect, useMemo, useRef } from "react";
import ReactECharts, { type EChartsInstance } from "echarts-for-react";
import { useTheme } from "@datwyler/mfe-shared-components";
import withWidget from "@components/withWidget";
import SelectFilter from "@components/Filters/select";
import { getOptions } from "./echart";
import { StyledCard, StyledTitle } from "./styled";
import { MeterProps } from "@types";
const Meter = ({
  sites,
  selectedSite,
  setSelectedSite,
  title,
  value,
  maxValue,
  splitNumber,
  unit,
}: MeterProps) => {
  const chartRef = useRef<EChartsInstance | null>();
  const theme = useTheme();

  useEffect(() => {
    const chartInstance = chartRef.current?.getEchartsInstance();
    if (chartInstance) {
      chartInstance.resize();
    }
  }, []);

  const options = useMemo(() => {
    return getOptions(value, theme, maxValue, splitNumber, unit);
  }, [value, theme, maxValue, splitNumber, unit]);

  const Title = () => {
    return (
      <StyledTitle>
        <div className="meter-title">
          <span>{title}</span>
        </div>
        <SelectFilter
          options={sites}
          handleSelectChange={(siteId) => setSelectedSite(siteId)}
          value={selectedSite}
        />
      </StyledTitle>
    );
  };

  return (
    <StyledCard title={<Title />} bordered={false}>
      <ReactECharts
        ref={chartRef}
        option={options}
        notMerge={true}
        lazyUpdate={true}
      />
    </StyledCard>
  );
};

export default withWidget(Meter);
