import { Flex } from "antd";
import Select from "@components/Filters/select";
import { HumdityTempFilterProps } from "@types";
import { useIntl } from "react-intl";
const Filter = ({
  selectedPeriod,
  selectedSite,
  selectedDevice,
  handlePeriodChange,
  handleSiteChange,
  handleDeviceChange,
  sites,
  devices,
}: HumdityTempFilterProps) => {
  const intl = useIntl();
  
  const durationOptions = [
    { value: "day", label: intl.formatMessage({ id: "day" }) },
    { value: "hour", label: intl.formatMessage({ id: "hour" }) },
    { value: "week", label: intl.formatMessage({ id: "week" }) },
  ];
  return (
    <Flex style={{ paddingLeft: "9px" }}>
      <Select
        options={durationOptions}
        value={selectedPeriod}
        handleSelectChange={handlePeriodChange}
      />
      <Select
        options={sites}
        value={selectedSite}
        handleSelectChange={handleSiteChange}
      />
      <Select
        options={devices}
        value={selectedDevice}
        handleSelectChange={handleDeviceChange}
      />
    </Flex>
  );
};

export default Filter;
