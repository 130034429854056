import { GridWidgetsProps } from "@types";
import {
  WidgetContainer,
  WidgetItem,
  PrimaryText,
  SecondaryText,
} from "./styled";

const GridWidets = ({ widgets, token }: GridWidgetsProps) => {
  return (
    <WidgetContainer>
      {widgets.map(({ Icon, value, name }, index) => (
        <WidgetItem key={index}>
          <Icon
            style={{ color: token.datwylerIcon.iconBrand }}
            bgColor={token.datwylerSurface.surfaceBrand1}
            height={56}
            width={56}
          />
          <div>
            <PrimaryText>{value}</PrimaryText>
            <SecondaryText>{name}</SecondaryText>
          </div>
        </WidgetItem>
      ))}
    </WidgetContainer>
  );
};

export default GridWidets;
