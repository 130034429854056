export const NAV_FULL_WIDTH = 280;
export const NAV_COLLAPSE_WIDTH = 0;
export const ACTIVE = "ACTIVE";

export const ONLINE_DEVICES = "ONLINE_DEVICES";
export const SMOKE_DETECTOR = "SMOKE_DETECTOR";
export const DOOR_CONTACT = "DOOR_CONTACT";
export const PDU = "PDU";
export const CRITICAL_ALARM = "CRITICAL_ALARM";
export const WATER_LEAK = "WATER_LEAK";

export const COOLING_SYSTEM = "COOLING_SYSTEM";
export const UPS = "UPS";

export const ALARM_STATUS_COLOR = {
  ACTIVE: "#06931D",
  IN_PROGRESS: "#F88D0F",
  ESCALATED: "#F88D0F",
  RESOLVED: "#8F9399",
  DEACTIVATED: "#EA233E",
};

export const TEMP_HUMIDITY = "TEMP_HUMIDITY";
export const MAIN_METER = "MAIN_METER";
export const IT_METER = "IT_METER";
export const PUE_METER = "PUE";
export const UPS_ALARM = "UPS_ALARM";
export const COOLING_SYSTEM_ALARM = "COOLING_SYSTEM_ALARM";

export const WIDGETS = [
  TEMP_HUMIDITY,
  MAIN_METER,
  IT_METER,
  PUE_METER,
  UPS_ALARM,
  COOLING_SYSTEM_ALARM,
];

export const GATEWAY = "GATEWAY";

export const MODEM_TEMPERATURE = "modem_temperature";
export const TEMPERATURE = "temperature";
export const HUMIDITY = "humidity";

export const CRITICAL = "CRITICAL";
export const WARNING = "WARNING";
export const INFORMATION = "INFORMATION";
