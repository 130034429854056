import { styled } from "@datwyler/mfe-shared-components";

export const WidgetContainer = styled.div`
  display: grid;
  z-index:1;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(6, 350px);
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.datwylerBorder.borderPrimary};
  background-color: ${(props) => props.theme.datwylerSurface.SurfacePrimary};
  overflow: scroll;
  scrollbar-width: none;
  @media screen and (min-width: 768px) {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const WidgetItem = styled.div`
  border-right: 1px solid ${(props) => props.theme.datwylerBorder.borderPrimary};
  display: flex;
  align-items: center;
  height: 88px;
  justify-content: flex-start;
  padding: 16px;
  gap: 12px;
  &:last-child {
    border-right: 0px;
  }
  @media screen and (min-width: 768px) {
    border-bottom: 1px solid
      ${(props) => props.theme.datwylerBorder.borderPrimary};
    &:nth-child(3n) {
      border-right: 0px;
    }
    &:nth-last-child(-n + 3) {
      border-bottom: 0px;
    }
  }
`;

export const PrimaryText = styled.span`
  font-size: 28px;
  line-hight: 36px;
  font-weight: 500;
  display: block;
  color: ${(props) => props.theme.datwylerText.textPrimary};
`;

export const SecondaryText = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.datwylerText.textTertiary};
  display: block;
`;
