import { Select } from "./styled";

const SelectFilter = ({
  options,
  handleSelectChange,
  value,
}: {
  options: { value: string; label: string }[];
  handleSelectChange: (siteId: string) => void;
  value: string;
}) => (
  <Select
    variant="borderless"
    value={value}
    popupMatchSelectWidth={250}
    listHeight={180}
    onChange={handleSelectChange}
  >
    {options.map(({ value, label }, index) => (
      <Select.Option key={index} value={value}>
        {label}
      </Select.Option>
    ))}
  </Select>
);

export default SelectFilter;
