import { styled } from "@datwyler/mfe-shared-components";

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
`;
