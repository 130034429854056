import { useEffect, useState, useCallback } from "react";
import {
  useTenant,
  useLocation,
  lodash as _,
} from "@datwyler/mfe-shared-components";

const useGridWidgets = (tenantId, tenantDetails) => {
  const [tenantID, setTenantID] = useState(tenantId);
  const { updateTenant, updateTenantResponseData } = useTenant();
  const { fetchLocationData, fetchLocations } = useLocation();

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId, setTenantID]);

  const getSites = useCallback(() => {
    fetchLocations({
      variables: { tenantId: tenantID },
    });
  }, [fetchLocations, tenantID]);

  const updateWidgetList = (widgetList) => {
    if (tenantDetails) {
      updateTenant({
        variables: {
          input: {
            id: tenantDetails.id,
            status: tenantDetails.status,
            managed: tenantDetails.managed,
            dashboard: { widgets: widgetList },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (tenantID) {
      getSites();
    }
  }, [tenantID, getSites]);

  return {
    widgetsData: tenantDetails,
    locationsData: _.get(fetchLocationData, "locations", []),
    updateWidgetList: updateWidgetList,
    updateTenantResponseData: updateTenantResponseData,
  };
};

export default useGridWidgets;
