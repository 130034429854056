import { useIntl } from "react-intl";
import { Row, Col } from "antd";
import UPSDevice from "@components/Widgets/UPSDevice";
import CoolingUnits from "@components/Widgets/CoolingUnits";
import MainMeter from "@components/Widgets/MainMeter";
import ITMeter from "@components/Widgets/ITMeter";
import PUEMeter from "@components/Widgets/PUEMeter";
import HumdityTempChart from "@components/Widgets/Humdity&Temp";
import { Widgetsprops } from "@types";

import {
  MAIN_METER,
  IT_METER,
  PUE_METER,
  UPS_ALARM,
  COOLING_SYSTEM_ALARM,
  TEMP_HUMIDITY,
} from "@constants";

const Widgets = ({ commonProps, widgetList }: Widgetsprops) => {
  const intl = useIntl();

  const widgetComponentMapping = [
    {
      name: TEMP_HUMIDITY,
      component: HumdityTempChart,
      properties: {
        span: { xs: 24, md: 24, lg: 24 },
        ...commonProps(TEMP_HUMIDITY),
      },
      position: 0,
    },
    {
      name: MAIN_METER,
      component: MainMeter,
      properties: {
        ...commonProps(MAIN_METER),
        span: { xs: 24, md: 12, lg: 8 },
        sites: [
          { label: intl.formatMessage({ id: "home_all_sites" }), value: "" },
          ...commonProps(MAIN_METER).sites,
        ],
      },
      position: 1,
    },
    {
      name: IT_METER,
      component: ITMeter,
      properties: {
        ...commonProps(IT_METER),
        sites: [
          { label: intl.formatMessage({ id: "home_all_sites" }), value: "" },
          ...commonProps(MAIN_METER).sites,
        ],
        span: {
          xs: 24,
          md: 12,
          lg: 8,
        },
        position: 2,
      },
    },
    {
      name: PUE_METER,
      component: PUEMeter,
      properties: {
        ...commonProps(PUE_METER),
        sites: [
          { label: intl.formatMessage({ id: "home_all_sites" }), value: "" },
          ...commonProps(MAIN_METER).sites,
        ],
        span: {
          xs: 24,
          md: 12,
          lg: 8,
        },
        position: 3,
      },
    },
    {
      name: UPS_ALARM,
      component: UPSDevice,
      properties: {
        ...commonProps(UPS_ALARM),

        sites: [
          { label: intl.formatMessage({ id: "home_all_sites" }), value: "" },
          ...commonProps(MAIN_METER).sites,
        ],
        span: {
          xs: 24,
          xl: 12,
        },
        position: 4,
      },
    },
    {
      name: COOLING_SYSTEM_ALARM,
      component: CoolingUnits,
      properties: {
        ...commonProps(COOLING_SYSTEM_ALARM),

        sites: [
          { label: intl.formatMessage({ id: "home_all_sites" }), value: "" },
          ...commonProps(MAIN_METER).sites,
        ],
        span: {
          xs: 24,
          xl: 12,
        },
        position: 5,
      },
    },
  ];

  const widgetToRender = widgetComponentMapping
    .filter(({ name }) => {
      return widgetList.indexOf(name) >= 0;
    })
    .sort((a, b) => {
      return a.position - b.position;
    });

  return (
    <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
      {widgetToRender.map((widget) => {
        const { component: WidgetComponent, properties, name } = widget;
        const { span, ...restProps } = properties;
        return (
          <Col {...span} key={name}>
            {<WidgetComponent {...restProps} />}
          </Col>
        );
      })}
    </Row>
  );
};

export default Widgets;
