import { useIntl } from "react-intl";
import { type TableProps, Badge } from "antd";
import { Table, moment } from "@datwyler/mfe-shared-components";
import withWidget from "@components/withWidget";
import SelectFilter from "@components/Filters/select";
import { getAlarmSeverityIcon } from "@helpers";
import { DeviceTableProps, DeviceTableDataProps } from "@types";
import { ACTIVE } from "@constants";
import { Title, TitleWrapper } from "./styled";

/** find solution on how to export types */
const DeviceTable = ({
  sites,
  title,
  data = [],
  selectedSite,
  setSelectedSite,
}: DeviceTableProps) => {
  const intl = useIntl();
  const columns: TableProps<DeviceTableDataProps>["columns"] = [
    {
      key: "site",
      dataIndex: "siteName",
      title: intl.formatMessage({ id: "home_widget_site_col" }),
      render: (siteName, { siteStatus }) => {
        return (
          <Badge
            key={siteName}
            color={siteStatus === ACTIVE ? "#0DB328" : "#F82834"}
            text={siteName}
          />
        );
      },
    },
    {
      key: "device",
      dataIndex: "device",
      title: intl.formatMessage({ id: "home_widget_device_col" }),
    },
    {
      key: "alert",
      dataIndex: "alert",
      title: intl.formatMessage({ id: "home_widget_alert_col" }),
    },
    {
      key: "severity",
      dataIndex: "severity",
      title: intl.formatMessage({ id: "home_widget_severity_col" }),
      align: "center",
      render: (value) => {
        return <> {getAlarmSeverityIcon(value, {})}</>;
      },
    },
    {
      key: "Date",
      dataIndex: "time",
      title: intl.formatMessage({ id: "home_widget_date_col" }),
      render: (time) => {
        return moment.unix(time).format("DD-MM-YYYY HH:mm:ss");
      },
    },
  ];

  const header = () => (
    <TitleWrapper>
      <Title>
        {title}
        <span style={{ paddingLeft: "4px" }}>({data.length})</span>
      </Title>
      <SelectFilter
        options={sites}
        handleSelectChange={(siteId) => setSelectedSite(siteId)}
        value={selectedSite}
      />
    </TitleWrapper>
  );

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        title={header}
        scroll={{ x: "auto" }}
        headerPadding={"0px 0px 16px 0px"}
        pagination={false}
      />
    </>
  );
};

export default withWidget(DeviceTable);
