import { Button as B, Checkbox as CB } from "antd";
import { styled } from "@datwyler/mfe-shared-components";

export const Title = styled.div`
  span {
    color: ${(props) => props.theme.datwylerText.textPrimary};
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const Checkbox = styled(CB)`
  font-size: 20px;
  line-height: 28px;
  font-family: Lato;
  .ant-checkbox-inner {
    height: 24px;
    width: 24px;
  }
`;

export const Button = styled(B)`
  border: none;
  box-shadow: none;
  height: 48px;
  line-height: 24px;
  font-size: 16px;
  padding: 12px 24px 12px 24px;
  font-weight: 600;
`;
