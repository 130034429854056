import ReactECharts from "echarts-for-react";
import { useIntl } from "react-intl";
import { useTheme, lodash as _ } from "@datwyler/mfe-shared-components";
import Tabs from "@components/Tabs";
import useTelemetry from "@hooks/useTelemetry";
import { HumdityTempChartProps } from "@types";
import { HUMIDITY, TEMPERATURE } from "@constants";
import Filter from "./filter";
import getOption from "./echart";

const HumdityTempChart = ({
  sites,
  tenantID,
  actions,
}: HumdityTempChartProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const {
    setSelectedTelemetry,
    setSelectedPeriod,
    setSelectedDevice,
    setSelectedSite,
    selectedDevice,
    selectedSite,
    selectedPeriod,
    devices,
    chartData,
    precedingChartData,
    latestTelemetry,
  } = useTelemetry(tenantID, sites);

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };

  const handleSiteChange = (value) => {
    setSelectedSite(value);
  };

  const handleDeviceChange = (value) => {
    setSelectedDevice(value);
  };

  const handleTabChange = (telemetryName) => {
    setSelectedTelemetry(telemetryName);
  };

  const Chart = () => {
    return (
      <>
        <ReactECharts
          option={getOption(
            chartData,
            precedingChartData,
            intl,
            selectedPeriod,
            theme
          )}
          style={{
            maxWidth: "100%",
            width: "100%",
            height: "390px",
            marginBottom: "8px",
          }}
        />
        <Filter
          selectedPeriod={selectedPeriod}
          selectedSite={selectedSite}
          selectedDevice={selectedDevice}
          handlePeriodChange={handlePeriodChange}
          handleSiteChange={handleSiteChange}
          handleDeviceChange={handleDeviceChange}
          sites={sites}
          devices={devices}
        />
      </>
    );
  };

  const tabs = [
    {
      key: HUMIDITY,
      title: intl.formatMessage({ id: "home_widget_humidity" }),
      value: _.get(latestTelemetry, "humidity.value", ""),
      unit: "%",
      Component: () => <Chart />,
    },
    {
      key: TEMPERATURE,
      title: intl.formatMessage({ id: "home_widget_temperature" }),
      value: _.get(latestTelemetry, "temperature.value", ""),
      unit: "°C",
      Component: () => <Chart />,
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      handleTabChange={handleTabChange}
      defaultActiveKey={HUMIDITY}
      actions={actions}
    />
  );
};

export default HumdityTempChart;
