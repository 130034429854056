import { StyledWidget, Actions } from "./styled";

function withWidget<P>(WrappedComponent: React.ComponentType<P>) {
  return function WidgetComponent(
    props: P & { actions: Array<React.ReactNode> }
  ) {
    const { actions } = props;
    return (
      <StyledWidget>
        <WrappedComponent {...props} />
        <Actions>{actions}</Actions>
      </StyledWidget>
    );
  };
}

export default withWidget;
