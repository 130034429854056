import { Typography } from "antd";
import { TabTitleType } from "@types";
import { TabTitle } from "./styled";

const { Text } = Typography;
const Title = (props: TabTitleType) => {
  const { label, value, unit } = props;
  return (
    <TabTitle>
      <Text className="title">{label}</Text>

      <Text className="value">
        {value || "-"}
        {value && <span>{unit}</span>}
      </Text>
    </TabTitle>
  );
};

export default Title;
