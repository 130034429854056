import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
// import { ConfigProvider } from "antd";
import {
  AxiosApiProvider,
  GraphQLApiProvider,
  TranslationProvider,
  NotificationProvider,
  DarkModeProvider,
  ContentWrapper,
  useLocale,
} from "@datwyler/mfe-shared-components";
import App from "./app";
const Root = ({
  GRAPHQL_URL,
  SECURED_GATEWAY_URL,
}: {
  GRAPHQL_URL: string;
  SECURED_GATEWAY_URL: string;
}) => {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);
  return (
    <BrowserRouter>
      <TranslationProvider locale={locale}>
        <NotificationProvider>
          <AxiosApiProvider uri={SECURED_GATEWAY_URL}>
            <GraphQLApiProvider uri={GRAPHQL_URL}>
              <DarkModeProvider>
                <ContentWrapper>
                  <App />
                </ContentWrapper>
              </DarkModeProvider>
            </GraphQLApiProvider>
          </AxiosApiProvider>
        </NotificationProvider>
      </TranslationProvider>
    </BrowserRouter>
  );
};

export default Root;
