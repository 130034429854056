import { IntlShape } from "react-intl";
import Modal from "@components/Modal";

const DeleteConfirmModal = ({
  open,
  intl,
  handleOk,
  handleCancel,
}: {
  open: boolean;
  intl: IntlShape;
  handleOk: () => void;
  handleCancel: () => void;
}) => {
  return (
    <Modal
      title={intl.formatMessage({ id: "home_widget_del_dialog" })}
      open={open}
      okText={intl.formatMessage({ id: "home_widget_del_btn" })}
      okType="danger"
      okButtonProps={{
        type: "primary",
        size: "large",
      }}
      cancelButtonProps={{
        size: "large",
      }}
      cancelText={intl.formatMessage({ id: "home_widget_cancel" })}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      {intl.formatMessage({ id: "home_widget_del_text" })}
    </Modal>
  );
};

export default DeleteConfirmModal;
