
import { styled } from "@datwyler/mfe-shared-components";
import { Select as S } from "antd";

export const Select = styled(S)`
  .ant-select-selection-item {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
`;