import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useWidgets, lodash as _ } from "@datwyler/mfe-shared-components";
import Meter from "@components/Meter";
import { MainMeterProps } from "@types";

const MainMeter = ({ sites, actions, tenantID }: MainMeterProps) => {
  const intl = useIntl();
  const [selectedSite, setSelectedSite] = useState("");
  const { fetchMainMeter, fetchMainMeterData } = useWidgets();

  const getMeterReading = useCallback(() => {
    tenantID &&
      fetchMainMeter({
        variables: {
          tenantId: tenantID,
          ...(selectedSite && { siteId: selectedSite }),
        },
      });
  }, [tenantID, fetchMainMeter, selectedSite]);

  useEffect(() => {
    getMeterReading();
  }, [getMeterReading]);

  const value = _.get(fetchMainMeterData, "mainMeterReading.value", 0);
  const unit = _.get(fetchMainMeterData, "mainMeterReading.unit", "");
  return (
    <Meter
      sites={sites}
      selectedSite={selectedSite}
      setSelectedSite={setSelectedSite}
      actions={actions}
      title={intl.formatMessage({ id: "home_widget_main_meter" })}
      value={value}
      maxValue={value}
      splitNumber={10}
      unit={unit}
    />
  );
};

export default MainMeter;
