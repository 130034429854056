import {
  lodash as _,
  InfoCircleFilled,
  WarningOutlined,
  ExclamationCircleOutlined,
} from "@datwyler/mfe-shared-components";
import {
  ONLINE_DEVICES,
  SMOKE_DETECTOR,
  DOOR_CONTACT,
  PDU,
  CRITICAL_ALARM,
  WATER_LEAK,
  ACTIVE,
  GATEWAY,
  TEMP_HUMIDITY,
} from "@constants";
import { BreakpointMap, GridWidgetsType, Severity } from "@types";
import DoorIcon from "@images/door.svg?component";
import SensorIcon from "@images/sensor.svg?component";
import WaterIcon from "@images/water.svg?component";
import DeviceIcon from "@images/device.svg?component";
import PDUAlertIcon from "@images/pdu-alert.svg?component";
import AlertIcon from "@images/alert.svg?component";

export const isNavBarCollapsed = (breakpoint: BreakpointMap): boolean => {
  return !breakpoint.xl;
};

export const transformGridWidgets = (widgetsData = {}, intl) => {
  const widgets = [
    ONLINE_DEVICES,
    SMOKE_DETECTOR,
    DOOR_CONTACT,
    PDU,
    CRITICAL_ALARM,
    WATER_LEAK,
  ];
  const devices = _.get(widgetsData, "devices", { online: 0, total: 0 });

  return widgets.map((widget) => {
    switch (widget) {
      case ONLINE_DEVICES:
        return {
          value: `${devices.online}/${devices.total}`,
          Icon: DeviceIcon,
          name: intl.formatMessage({ id: "home_widget_online_devices" }),
        };
      case SMOKE_DETECTOR:
        return {
          value: _.get(widgetsData, `alarms.byDeviceType.${SMOKE_DETECTOR}`, 0),
          Icon: SensorIcon,
          name: intl.formatMessage({ id: "home_widget_smoke_sensor" }),
        };
      case DOOR_CONTACT:
        return {
          value: _.get(widgetsData, `alarms.byDeviceType.${DOOR_CONTACT}`, 0),
          Icon: DoorIcon,
          name: intl.formatMessage({ id: "home_widget_door_alarm" }),
        };
      case PDU:
        return {
          value: _.get(widgetsData, `alarms.byDeviceType.${PDU}`, 0),
          Icon: PDUAlertIcon,
          name: intl.formatMessage({ id: "home_widget_pdu_alert" }),
        };
      case CRITICAL_ALARM:
        return {
          value: _.get(widgetsData, `alarms.bySeverity.CRITICAL`, 0),
          Icon: AlertIcon,
          name: intl.formatMessage({ id: "home_widget_critical_alarm" }),
        };
      case WATER_LEAK:
        return {
          value: _.get(widgetsData, `alarms.byDeviceType.${WATER_LEAK}`, 0),
          Icon: WaterIcon,
          name: intl.formatMessage({ id: "home_widget_water_leak" }),
        };
    }
  }) as GridWidgetsType;
};

export const transformDeviceTable = (data = {}, type) => {
  const deviceData = [];
  const alarmsData = _.get(data, "alarms.alarms", []);
  alarmsData.map((alarm) => {
    const deviceType = _.get(alarm, "device.type", "");
    if (deviceType === type) {
      const data = {
        key: alarm.id,
        siteName: _.get(alarm, "device.site.name", ""),
        siteStatus: _.get(alarm, "device.site.status", ""),
        device: _.get(alarm, "device.name", ""),
        alert: _.get(alarm, "description", ""),
        severity: _.get(alarm, "severity", ""),
        time: _.get(alarm, "time", ""),
      };
      deviceData.push(data);
    }
  });
  return deviceData;
};

export const transformSitesOption = (locations = []) => {
  const options = [];
  locations.forEach((location) => {
    if (
      location.status === ACTIVE &&
      _.get(location, "sites", [])?.length > 0
    ) {
      location.sites.map((site) => {
        if (_.get(site, "status", "") === ACTIVE) {
          options.push({
            label: site.name,
            value: site.id,
          });
        }
      });
    }
  });
  return _.orderBy(options, "label", "asc");
};

export const transformDeviceOption = (data = {}) => {
  const options = [];
  const devices = _.get(data, "devices.devices", []);
  devices.map((device) => {
    const status = _.get(device, "status", "") === ACTIVE;
    const deviceType = [GATEWAY, TEMP_HUMIDITY].includes(
      _.get(device, "type", "")
    );
    if (status && deviceType) {
      options.push({
        label: device.name,
        value: device.id,
      });
    }
  });
  return options;
};

export const transformTempHumidity = (telemetryData = []) => {
  const trasnformedData = [];
  telemetryData.forEach((telemetry) => {
    const time = _.get(telemetry, "time", "");
    const value = _.get(telemetry, "value", undefined);
    trasnformedData.push([time * 1000, value]);
  });
  return trasnformedData;
};

export const getAlarmSeverityIcon = (
  severity: Severity,
  tokens: Record<string, string>
) => {
  const style = { fontSize: 14 };
  switch (severity) {
    case Severity.INFORMATION:
      return (
        <InfoCircleFilled
          style={{
            ...style,
            color: _.get(tokens, "datwylerIcon.iconSuccess", "#06931D"),
          }}
        />
      );
    case Severity.WARNING:
      return (
        <WarningOutlined
          style={{
            ...style,
            color: _.get(tokens, "datwylerIcon.iconWarning", "#F88D0F"),
          }}
        />
      );
    case Severity.CRITICAL:
      return (
        <ExclamationCircleOutlined
          style={{
            ...style,
            color: _.get(tokens, "datwylerIcon.iconError", "#EA233E"),
          }}
        />
      );
    default:
      <></>;
  }
};
